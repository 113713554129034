<template>
  <div>
    <b-row class="m-0" style="position: relative;"> 
        <b-card class="m-0 border-bottom rounded-0 w-100" v-if="student && authUser().user.type != 2">
            <div class="d-flex justify-content-between">
                <div class="d-flex align-items-center align-items-center">
                    <b-avatar 
                        v-if="student.avatar" 
                        :src="student.avatar"  
                        size="50"
                        style=" background: linear-gradient(0deg, rgba(105,34,149,1) 0%, rgba(232,65,133,1) 100%);"
                    />
                    <div class="ml-2">
                        <h5 class="m-0" >{{ `${student.fname} ${student.lname}` }}</h5>
                    </div>
                    <small class="ml-2">
                        Expand
                        <feather-icon
                            :tooltip="'none'"
                            icon="ChevronDownIcon"
                            size="16"
                        />
                    </small>
                </div>
                <b-button 
                    class="btn rounded-pill py-60 px-3" 
                    variant="primary"
                    style="height: fit-content;"
                    @click="$router.go(-1)"
                >
                    Back
                </b-button>
            </div>
        </b-card>
        <b-tabs 
            :class="$store.state.appConfig.layout.skin == 'dark' ? 'cutom-tabs-dark w-100 rounded-0' : 'w-100 rounded-0'" 
            content-class="mt-2 w-100 px-2" 
            lazy
        >
            <b-tab title="Video feedback" active>
                <McqQuestionComponent @refresh="init" :topicType="feedback.type" :question="feedback.subtopics && feedback.subtopics.length > 0 ? feedback.subtopics[0].questions : []" />
            </b-tab>
            <b-tab title="Assessment feedback">
                <OpenQuestionComponent @refresh="init" :topicType="feedback.type" :questions="feedback.subtopics" />
                <ImageComponent @refresh="init" :topicType="feedback.type" :topicData="feedback.subtopics" />
                <TextComponent @refresh="init" :topicType="feedback.type" :topicData="feedback.subtopics" />
                <PDFComponent @refresh="init"  :topicType="feedback.type" :topicData="feedback.subtopics" />
                <ThingLinkComponent @refresh="init"  :topicType="feedback.type" :topicData="feedback.subtopics" />
                <YoutubeComponent @refresh="init"  :topicType="feedback.type" :topicData="feedback.subtopics" />
            </b-tab>
            <b-tab title="MCQ feedback">
                <AssessmentComponent @refresh="init"  :topicType="feedback.type" :question="feedback.subtopics && feedback.subtopics.length > 0 ? feedback.subtopics[0].questions : []" />
            </b-tab>
        </b-tabs>
    </b-row>

  </div>
</template>

<script>
import Ripple from "vue-ripple-directive";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import { mapGetters } from "vuex";
import VueApexCharts from "vue-apexcharts";
import vSelect from "vue-select";
import OpenQuestionComponent from "@/views/modules/students/OpenQuestionComponent.vue";
import McqQuestionComponent from "@/views/modules/students/McqQuestionComponent.vue";
import AssessmentComponent from "@/views/modules/students/AssessmentComponent.vue";
import ImageComponent from "@/views/modules/students/ImageComponent.vue";
import TextComponent from "@/views/modules/students/TextComponent.vue";
import PDFComponent from "@/views/modules/students/PDFComponent.vue";
import ThingLinkComponent from "@/views/modules/students/ThingLinkComponent.vue";
import YoutubeComponent from "@/views/modules/students/YoutubeComponent.vue";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { required, email } from "@validations";
import {
  BTable,
  BButton,
  BFormCheckbox,
  BCard,
  BRow,
  BCol,
  BBadge,
  BCardText,
  BAvatar,
  VBTooltip,
  BLink,
  BSpinner,
  BPagination,
  BInputGroup,
  BFormInput,
  BTab, BTabs,
  BFormTextarea,
  BFormFile,
  BForm
} from "bootstrap-vue";

export default {
  name: "topics",
  components: {
    OpenQuestionComponent,
    McqQuestionComponent,
    AssessmentComponent,
    ImageComponent,
    TextComponent,
    PDFComponent,
    ThingLinkComponent,
    YoutubeComponent,
    BTable,
    BButton,
    BFormCheckbox,
    BCard,
    BRow,
    BCol,
    BBadge,
    BAvatar,
    BCardText,
    BLink,
    BSpinner,
    BPagination,
    ToastificationContent,
    vSelect,
    VueApexCharts,
    BTab, BTabs,
    BInputGroup,
    BFormInput,
    BFormTextarea,
    BFormFile,
    BForm,
    ValidationProvider,
    ValidationObserver
  },
  directives: {
    Ripple,
    "b-tooltip": VBTooltip
  },
  computed: {
    ...mapGetters({
      feedback: "students/feedback",
      student: "users/user",
    }),
    student_id(){ 
        return this.$route.params.id ? this.$route.params.id : null
    },
    topic_id(){
        return this.$route.query.topic_id ? this.$route.query.topic_id : null;
    }
  },
  data() {
    return {
      grade: 0,
      comment: null,
    };
  },
  methods: {
    init(){
        this.$store.dispatch('users/showUser', this.student_id ? this.student_id : this.authUser().user.id)
        const payload = { query: { student_id: this.student_id ? this.student_id : this.authUser().user.id, topic_id: this.topic_id } };
        this.$store.dispatch("students/feedback", payload).then(_=>{
        })
    },
  },
  mounted() {
    this.init()
  }
};
</script>
<style lang="css">
.custom-input-grade {
  background-color: #f8f9fa; /* Gray background */
  width: 150px; /* Custom width */
  padding: 0.5rem; /* Optional: Adjust padding */
}
[dir] .nav-tabs {
    margin-bottom: 1rem !important;
    background-color: #fff !important;
    padding-top: 10px;
}
.cutom-tabs-dark .nav-tabs{
    background-color: rgb(11, 11, 11) !important;
}
</style>