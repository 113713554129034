<template>
    <div>
        
        <b-card v-if="trigger && topicType == 1" class="m-0 p-0"  :class="$store.state.appConfig.layout.skin == 'dark' ? 'custom-dark-card': ''"  style="border-radius: 20px !important;">
            <h3 class="font-weight-bolder">Quiz 1</h3>
            <div v-for="(item, index) in question" >
                <h3 class="mb-1.25 font-weight-bolder">Question {{ index+1 }}</h3>
                <p style="font-size: 12px" v-if="item">{{ item.question }}</p>
                <h3 class="mt-2 mb-1.25 font-weight-bolder">Student response</h3>
                <h5 class="mt-2">Selected</h5>
                <div  style="font-size: 12px;" v-if="item && item.answers.length > 0">
                    {{ getStudentAnswer(item.answers[0].selected_option, index) }} - 
                    <span class="text-success" v-if="item.answers[0].correct == 1" > correct </span> 
                    <span class="text-danger" v-else > Incorrect </span> 
                </div>
                <h5 class="mt-2">Additional options</h5>    
                <div  style="font-size: 12px;" v-if="item">
                    <div class="d-flex m-0" v-for="option in AdditionalOptions( item.answers.length > 0 ? item.answers[0].selected_option : null , index)" >
                        <p style="font-size:30px ;" > .</p> <p style="margin: 8px 8px">{{option.value}}</p>
                    </div>
                </div>
                <!-- <div v-if="authUser().user.type != 2">
                    <h3 class="mt-2 mb-1.25 font-weight-bolder">LLM feedback</h3>
                    <p style="font-size: 12px;" >Lorem ipsum dolor sit amet consectetur adipisicing elit. Ab ipsum quaerat labore provident, animi iure maiores soluta obcaecati modi impedit velit? Necessitatibus dolorum a nesciunt ipsa non totam nostrum odit!</p>
                </div> -->
                <div v-if="authUser().user.type == 2">
                    <div v-if="item.answers[0].feedback.length > 0" >

                        <div v-for="(feedback, index) in item.answers[0].feedback" :key="index">
                            <div >
                                <h3 class="mt-2 mb-1.25 font-weight-bolder">Feedback</h3>
                                <p style="font-size: 12px;"> {{ item.answers[0].feedback.length > 0 ? item.answers[0].feedback[index].comment : ''}} </p>
                            </div>
                            <div>
                                <h3 class="mt-2 mb-1.25 font-weight-bolder">Grade</h3>
                                <p style="font-size: 12px;"> {{ item.answers[0].feedback.length > 0 ? item.answers[0].feedback[index].grade : ''}} </p>

                            </div>
                        </div>
                    </div>
                    <div v-else>
                    <p style="font-size: 12px;">No FeedBack Added</p>
                </div>
            </div>
                <div v-if="authUser().user.type != 2">
                    <h5 class="mt-2">Add score</h5>
                    <div class="d-flex">
                        <div style="width: 100px;"> 
                            <validation-provider
                                    #default="{ errors }"
                                    name="grade"
                                    vid="grade"
                                    rules="required"
                                >
                                    <b-input-group
                                        class="input-group-merge mr-1"
                                    >
                                        <b-form-input
                                            :disabled="item.answers.length == 0"
                                            :class="item.answers.length == 0 ? 'cursor-disabled' : ''"
                                            autocomplete="off"
                                            v-model="grade[index]"
                                            placeholder="0"
                                            id="grade"
                                            style="background-color: #f6f6f6;"
                                        />
                                        </b-input-group>
                                    <small class="text-danger float-left">{{ errors[0] }}</small>
                            </validation-provider>
                        </div>
                        <span style="margin: 10px 6px;"><h5>/ 100</h5></span>
                    </div>
                    <h5 class="mt-2">Comment</h5>
                    <!-- <validation-provider
                        #default="{ errors }"
                        name="comment"
                        vid="comment"
                        rules="required"
                        > -->
                        <b-form-group
                            
                        >
                            <b-input-group
                                class="input-group-merge mr-1"
                            >
                                <b-form-textarea
                                    :disabled="item.answers.length == 0"
                                    :class="item.answers.length == 0 ? 'cursor-disabled' : ''"
                                    id="textarea-default"
                                    v-model="comment[index]"
                                    placeholder=""
                                    style="background-color: #f6f6f6;"
                                ></b-form-textarea>
                                </b-input-group>
                            <!-- <small class="text-danger float-left">{{ errors[0] }}</small> -->
                        </b-form-group>
                    <!-- </validation-provider> -->
                    <div class="d-flex justify-content-end">
                        <b-button
                            :disabled="item.answers.length == 0"
                            :class="item.answers.length == 0 ? 'cursor-disabled' : ''"
                            variant="primary"
                            class="rounded-pill mt-2 pr-4 pl-4"
                            type="submit"
                            @click="submit(item.answers[0].id, index)"
                        >
                            save
                        </b-button>
                    </div>
                </div>
                
                <div v-if="question.length > index+1">
                    <hr/>
                </div>
            </div>
        </b-card>
    </div>
</template>
  
<script>
    import Ripple from "vue-ripple-directive";
    import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
    import { mapGetters } from "vuex";
    import VueApexCharts from 'vue-apexcharts';
    import vSelect from "vue-select";
    import { ValidationProvider, ValidationObserver,extend } from 'vee-validate'
    import { required, email } from '@validations'
    import {
        BTable,
        BButton,
        BFormCheckbox,
        BCard,
        BRow,
        BCol,
        BBadge,
        BCardText,
        BAvatar,
        VBTooltip,
        BLink,
        BSpinner,
        BPagination,
        BInputGroup,
        BFormInput,
        BFormTextarea,
        BFormFile,
        BForm,
        BListGroup,
        BListGroupItem,
        BFormGroup
    } from "bootstrap-vue";

    extend('max_value', {
        validate(value, { max }) {
            return value <= max;
        },
        params: ['max'],
        message: 'The {_field_} must be {max} or less.',
    });
    export default {
        props: [
            'question',
            'topicType'
        ],
        name: "topics",
        components: {
            BListGroup,
            BListGroupItem,
            BTable,
            BButton,
            BFormCheckbox,
            BCard,
            BRow,
            BCol,
            BBadge,
            BAvatar,
            BCardText,
            BLink,
            BSpinner,
            BPagination,
            ToastificationContent,
            vSelect,
            VueApexCharts,
            BInputGroup,
            BFormInput,
            BFormTextarea,
            BFormFile,
            BForm,
            ValidationProvider,
            ValidationObserver,
            BFormGroup
        },
        directives: {
            Ripple,
            "b-tooltip": VBTooltip,
        },
        computed: {
            student_id(){
                return this.$route.params.id ? this.$route.params.id : null
            },
            topic_id(){
                return this.$route.query.topic_id ? this.$route.query.topic_id : null;
            }
        },
        
        data() {
            return {
                grade: [],
                comment: [],
                trigger: true
            };
        },
        methods: {
            getStudentAnswer(id, index){
                const data = this.question[index].options;
                const selectedOption = data.find(option => option.id == id);
                return selectedOption['value'];
            },
            AdditionalOptions(id, index){
                if(id != null){
                    const data = this.question[index].options;
                    const selectedOptions = data.filter(option => option.id != id);
                    return selectedOptions;
                }else{
                    return this.question[index].options; 
                }
            },
            submit(answer_id,index){
                const payload = {
                    query:{ 
                        model_id: answer_id,
                        model_type: this.topicType == 1 ? 'Answer' : this.topicType == 2 ? 'Answer' : 'OQAnswer',
                        comment: this.comment[index],
                        grade: this.grade[index],
                    }
                }
                this.$store.dispatch('students/storeFeedback', payload).then(_=>{
                    this.$emit('refresh')
                    this.$swal({
                        icon: 'success',
                        title: 'Saved Successfully!',
                        confirmButtonColor: '#E84185',
                    })
                }).catch(error => {
                    if (error.response) {
                        this.$swal({
                            icon: 'error',
                            title: `<h4>${error.response.data.message}</h4>`,
                            showConfirmButton: true,
                            confirmButtonColor: '#E84185',
                            allowOutsideClick: true
                        });
                    }
                });
            },
            init(){
                if(this.topicType == 1){
                    this.trigger = false
                    this.question.forEach((el,index) => {
                        if(el.answers.length > 0 && el.answers[0].feedback.length > 0){
                            
                            
                            el.answers[0].feedback.forEach(element => {
                                if (element.user_role == 'Teacher' && this.hasRole('Teacher')) {
                                    console.log('element.grade Teacher',element.grade );
                                    
                                    this.grade[index] = element.grade ? element.grade : null 
                                    this.comment[index] = element.comment ? element.comment : null 
                                }
                                if (element.user_role == 'Admin' && this.hasRole('Admin')) {
                                    console.log('element.grade Admin',element.grade );

                                    this.grade[index] = element.grade ? element.grade : null 
                                    this.comment[index] = element.comment ? element.comment : null 
                                    }
                            });
                            
                            
                        }
                    });
                    this.trigger = true
                }
            }
        },
        mounted(){
            this.init()
        }
    };
</script>
<style lang="scss" scoped>
    .custom-input-grade {
        background-color: #f8f9fa; /* Gray background */
        width: 150px; /* Custom width */
        padding: 0.5rem; /* Optional: Adjust padding */
    }
    .custom-dark-card{
        border: 1px #3A3A3A solid;
        background-color: transparent;
    }
</style>